<template>
  <Mpopup v-model="doorModal" title="期望上门时间" 
    :maskClickable="false"
    height="56vh;">
    <div class="door-tips" v-if="!night">请至少提前半小时预约上门时间，若是紧急件请与快递员沟通，实际上门时间以协商后的为准</div>
    <div class="door-tips-strong" v-if="night">
      {{nightTips || '若快递员没有在当晚取件，夜间取件费将在取件后退还至您的账户'}}
    </div>
    <picker-view :value="indexArry" @change="bindDoorChange" v-if="doorModal">
      <picker-view-column>
        <div v-for="(item, index) in doordate" :key="index">{{item}}</div>
      </picker-view-column>
      <picker-view-column>
        <div class="doortime" v-for="(item, index) in doorTime" :key="index">
          <div style="text-align: left;">
            {{item.itemValue}}<span v-if="isExistMkt && item.itemName == 2" 
            :class="[night ? 'night-selected' : '']">+{{item.descr || ''}}</span>
            <span class="night-none" v-if="!isExistMkt && item.itemName == 2 && kdbest === 'Y'">约满</span>
          </div>
        </div>
      </picker-view-column>
    </picker-view>
    <div class="popup-bottom__btn">
      <button @tap="confirmDoor">完成</button>
    </div>
  </Mpopup>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
export default {
  name: 'doorTime',
  components: {
    Mpopup
  },
  data() {
    return {
      doorModal: false
    }
  },
  props: {
    value: Boolean, // 是否展示
    night: {
      type: Boolean,
      default: false
    },
    todaySent: {
      type: Boolean,
      default: false
    },
    indexArry: {
      type: Array,
      default: [0, 0]
    },
    doorTime: {
      type: Array,
      default: []
    },
    doordate: {
      type: Array,
      default: []
    },
    isExistMkt: {
      type: Boolean,
      default: false
    },
    nightTips: {
      type: String,
      default: ''
    },
    kdbest: {
      type: String,
      default: 'Y'
    }
  },
  watch: {
    value (val) {
      this.doorModal = val
    },
    doorModal (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    
  },
  mounted () {
    
  },
  methods: {
    bindDoorChange (e) {
      this.$emit('bindDoorChange', e)
    },
    confirmDoor () {
      this.$emit('confirmDoor')
    }
  }
}
</script>

<style lang="scss" scoped>
  .door-tips {
    font-size: 0.875rem;
    color: #888;
    padding: 0 1.25rem;
    &-strong {
      font-size: 0.875rem;
      color: #ff7f02;
      padding: 0 1.25rem;
    }
  }
  picker-view {
    width: 100%;
    height: 10.625rem;
    overflow: hidden;
    background-color: #fff;
    text-align: center;
    font-size: 1.0625rem;
    color: #333;
    &-column {
      overflow: hidden;
      &:first-child {
        flex: 0.4;
        justify-content: flex-end;
        font-size: 1.0625rem;
      }
      &:last-child {
        flex: 0.6;
        font-size: 0.875rem;
        text-align: left;
      }
      view {
        height: 2.625rem;
        line-height: 2.625rem;
        justify-self: flex-start;
      }
    }
  }
  .door-item {
    height: 2.625rem;
    display: flex;
    align-self: flex-start;
    text {
      margin-left: 0.9375rem;
    }
  }
  .night-none {
    margin-left: 0.9375rem;
    font-size: 0.875rem;
  }
  .night-selected {
    color: #ff7f02;
  }
  .popup-bottom__btn {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    button {
      width: 94%;
      height: 2.75rem;
      font-size: 1.0625rem;
      line-height: 2.75rem;
      border-radius: 0.25rem;
      background: #ff7f02;
      color: #fff;
      margin: 0.625rem auto;
      text-align: center;
    }
  }
</style>